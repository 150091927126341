import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['navbarBurger', 'navbarMenu'];

  connect() {
    this.navbarBurgerTarget.addEventListener('click', () => {
      this.navbarBurgerTarget.classList.toggle('is-active');
      this.navbarMenuTarget.classList.toggle('is-active');
    });
  }
}
