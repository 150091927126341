import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['invoiceType', 'invoiceInfo'];

  connect() {
    this.manageInvoiceInfo();
  }

  selectInvoiceType() {
    this.manageInvoiceInfo();
  }

  manageInvoiceInfo() {
    const type = this.invoiceTypeTarget.value;

    if (type === 'invoice') return this.showInvoiceInfo();
    if (type === 'ticket') return this.hideInvoiceInfo();

    return this.hideInvoiceInfo();
  }

  hideInvoiceInfo() {
    this.invoiceInfoTarget.classList.add('is-hidden');
    this.invoiceInfoTarget.querySelector('input[name="store[business_name]"]').disabled = true;
    this.invoiceInfoTarget.querySelector('input[name="store[activity]"]').disabled = true;
  }

  showInvoiceInfo() {
    this.invoiceInfoTarget.classList.remove('is-hidden');
    this.invoiceInfoTarget.querySelector('input[name="store[business_name]"]').disabled = false;
    this.invoiceInfoTarget.querySelector('input[name="store[activity]"]').disabled = false;
  }
}
