import { Controller } from '@hotwired/stimulus';
import consumer from '../channels/consumer';

export default class extends Controller {
  static targets = ['links', 'template', 'manual', 'importer', 'filename', 'creationType'];

  connect() {
    this.orderId = this.element.dataset.orderId;
    if (this.orderId) this.connectOrderCable();

    if (!this.hasCreationTypeTarget) return;
    const creationType = this.creationTypeTarget.value;
    if (creationType) this.showCreationOption(creationType);
  }

  connectOrderCable() {
    this.channel = consumer.subscriptions.create(
      { channel: 'OrdersChannel', id: this.orderId },
      {
        received: this.cableReceived.bind(this),
      },
    );
  }

  cableReceived(data) {
    if (data.payment === 'success') {
      window.location = `/orders/${this.orderId}?payment=success`;
    }
  }

  selectFile(e) {
    this.filenameTarget.textContent = e.target.files[0].name;
    this.filenameTarget.classList.remove('is-hidden');
  }

  selectCreationOption(e) {
    const value = e.target.defaultValue;

    this.showCreationOption(value);
  }

  showCreationOption(value) {
    if (value === 'file') {
      this.importerTarget.classList.remove('is-hidden');
      this.manualTarget.classList.add('is-hidden');
    }

    if (value === 'manual') {
      this.manualTarget.classList.remove('is-hidden');
      this.importerTarget.classList.add('is-hidden');
    }
  }

  addAssociation(event) {
    event.preventDefault();

    const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime());
    this.linksTarget.insertAdjacentHTML('beforebegin', content);
  }

  removeAssociation(event) {
    event.preventDefault();

    const wrapper = event.target.closest('.delivery-fields');

    // New records are simply removed from the page
    if (wrapper.dataset.newRecord === 'true') {
      wrapper.remove();

    // Existing records are hidden and flagged for deletion
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1;
      wrapper.style.display = 'none';
    }
  }
}
