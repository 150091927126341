import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['address', 'city'];

  connect() {
    if (typeof (google) !== 'undefined') {
      this.initMap();
    }
  }

  initMap() {
    const options = {
      componentRestrictions: { country: 'cl' },
      fields: ['address_components'],
      types: ['address'],
    };
    this.autocomplete = new google.maps.places.Autocomplete(this.addressTarget, options);
    this.autocomplete.addListener('place_changed', this.placeChanged.bind(this));
  }

  placeChanged() {
    const place = this.autocomplete.getPlace();

    const number = place.address_components.find((c) => c.types[0] === 'street_number')?.short_name;
    const street = place.address_components.find((c) => c.types[0] === 'route')?.short_name;
    const city = place.address_components.find((c) => c.types[0] === 'administrative_area_level_3')?.short_name;

    // Deliveries
    if (this.hasCityTarget) {
      const cityOption = Array.from(this.cityTarget.options)
        .find((o) => o.innerText === city)?.value;

      if (number) {
        this.addressTarget.value = `${street} ${number}`;
        this.cityTarget.value = cityOption;
      } else {
        this.addressTarget.value = street;
        this.cityTarget.value = cityOption;
      }
    }

    // Pick-up address
    if (!this.hasCityTarget) {
      if (number) {
        this.addressTarget.value = `${street} ${number}, ${city}`;
      } else {
        this.addressTarget.value = `${street}, ${city}`;
      }
    }
  }
}
